<template>
  <div class="page-container page-customType">
    <div class="operations">
        <div class="operation-btns">
            <div @click="goAdd">+新建模板</div>
        </div>
    </div>

    <!--TABLE-->
    <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%;"
       >
    <!-- <el-table-column label="序号"
            type="index"
            align="center"
            width="80">
    </el-table-column> -->

    <el-table-column
            prop="idenModelNumber"
            align="center"
            label="标识模板编码"
            >
    </el-table-column>
    <el-table-column
            label="创建时间"
            align="center"
            prop="createTime">
    </el-table-column>

    <el-table-column
            label="操作"
            align="center"
            width="200">
        <template slot-scope="scope">
            <div style="text-align: center;">
                <el-button class="checkCard" type="text" size="medium" @click="goDetail(scope.row)">查看</el-button>
                <el-button class="checkCard" type="text" size="medium" @click="goEdit(scope.row)">编辑</el-button>
                <el-button class="checkCard" type="text" size="medium" @click="handleDel(scope.row)">删除</el-button>
            </div>
        </template>
    </el-table-column>
</el-table>
<div class="page">
    <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageNum"
            :page-sizes="[10]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
    </el-pagination>
</div>
  </div>
</template>
<script>
import {  fetchIdenModelList, fetchDelIdenModel } from '@/api/customPage.js';
export default {
    data() {
        return {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          tableData: [],
        };
    },
    created() {

    },
    mounted() {
      this.init()
    },
    methods: {
        init() {
          let params = {
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          }
          fetchIdenModelList(params).then(res => {
            if(res.code === 200) {
              this.tableData = res.data.list
              this.total = res.data.total
            }
          })
        },
        handleCurrentChange (page) {
          this.pageNum = page
          this.init()
        },
        goAdd() {
           this.$router.push({path: "/mkc/configTemplateAdd", query: {modeType: 'add'}});
        },
        goEdit(row) {
           this.$router.push({path: "/mkc/configTemplateAdd", query: {modeType: 'edit', id: row.id, idenModelNumber: row.idenModelNumber, modelId: row.modelId}});
        },
        goDetail(row) {
           this.$router.push({path: "/mkc/configTemplateDetail", query: { id: row.id, idenModelNumber: row.idenModelNumber, modelId: row.modelId}});
        },
        handleDel(row) {
          this.$confirm('确认要删除吗?', '提示', {
            //type: 'warning'
          }).then(() => {
            // console.log('确认')
            let params = {
              id: row.id
            }
            fetchDelIdenModel(params).then(res => {
              if(res.code === 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              }
              this.init()
            })
          }).catch(() => {
            console.log('取消')
          });
        }
    }
}
</script>
<style scoped lang="scss">
.page-container{
  background: #fff;
}

.operations{
            display: flex;
            justify-content: space-between;
            height: 60px;
            align-items: center;
            .operation-btns{
                display: flex;
                div{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
            .search-user{
                display: flex;
                .search-input{
                    height: 30px;
                    margin-right: 10px;
                    .el-input__inner{
                        height: 30px;
                    }
                }
                .search-btn{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    cursor: pointer;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
        }
</style>